import React from 'react';
import { PageCenteredLayout } from '../layout/PageCenteredLayout';
import ProjectIcon from '../common/ProjectIcon';
import { ChevronRightIcon } from '@heroicons/react/24/solid';

interface IEvent {
  id: string;
  name: string;
  buildDate: Date;
  eventDate: Date;
  deleteDate: Date;
  solutionName: string;
  solutionIcon: string;
  accountsAllocated: number;
}

function getRandomEventName(index: number): string {
  const eventNames = [
    'Snowflake Summit',
    'Data Cloud Conference',
    'Snowflake Workshop',
    'Cloud Data Analytics',
    'Snowflake Innovation Day',
    'Data Warehouse Optimization',
    'Snowflake Performance Tuning',
    'Cloud Data Security',
    'Snowflake Integration Session',
    'Data Lake Best Practices',
    'Snowflake Partner Connect',
    'Data Sharing Symposium',
    'Snowflake Developer Meetup',
    'Data Governance Forum',
    'Snowflake User Group',
    'Data Transformation Workshop',
    'Snowflake Automation Seminar',
    'Real-Time Analytics Event',
    'Snowflake Migration Strategies',
    'Data Engineering Summit',
  ];

  return eventNames[index % eventNames.length] + ` ${index + 1}`;
}

function getRandomSolutionName(index: number): string {
  const solutionNames = [
    'Tasty Bytes',
    'Data Glacier',
    'Snowflake Express',
    'Cloud Crystals',
    'Data Blizzard',
    'Snowflake Harmony',
    'Insight Avalanche',
    'Snowy Pathways',
    'Data Icicle',
    'Frosty Solutions',
    'Crystal Clear Analytics',
    'Snowstorm Insights',
    'Frostbyte Solutions',
    'Chill Analytics',
    'Snowflake Nexus',
    'Data Drift',
    'Iceberg Innovations',
    'Snowflake Fusion',
    'Glacial Data',
    'Permafrost Solutions',
  ];

  return solutionNames[index % solutionNames.length];
}

function getRandomDate(baseDate: Date, minDays: number, maxDays: number): Date {
  const date = new Date(baseDate);
  const randomDays = Math.floor(Math.random() * (maxDays - minDays + 1)) + minDays;
  date.setDate(date.getDate() + randomDays);
  return date;
}

export default function EventManager() {
  const events: IEvent[] = Array.from({ length: 100 }, (_, index) => {
    const id = (index + 1).toString();
    const buildDate = new Date();

    // Ensure eventDate is after buildDate
    const eventDate = getRandomDate(buildDate, 1, 10);

    // Ensure deleteDate is after eventDate
    const deleteDate = getRandomDate(eventDate, 1, 5);

    return {
      id,
      name: getRandomEventName(index),
      buildDate,
      eventDate,
      deleteDate,
      solutionName: getRandomSolutionName(index),
      solutionIcon: '',
      accountsAllocated: Math.floor(Math.random() * 100), // Random number for example
    };
  });

  return (
    <PageCenteredLayout>
      <div className="relative grid grid-cols-12 gap-x-6 px-2 py-2 bg-white ">
        <div className="col-span-3 sm:col-span-3 flex min-w-0 gap-x-4">Event and Solution</div>
        <div className="hidden col-span-2 sm:flex items-center justify-center gap-x-4 text-sm">Build Date</div>
        <div className="hidden col-span-2 sm:flex items-center justify-center gap-x-4 text-sm">Event Date</div>
        <div className="hidden col-span-2 sm:flex items-center justify-center gap-x-4 text-sm">Delete Date</div>
        <div className="hidden col-span-2 sm:flex items-center justify-center gap-x-4 text-sm">Accounts allocated</div>
      </div>
      <ul role="list" className="w-full space-y-4">
        {events.map((event) => {
          return (
            <li
              key={event.id}
              className="relative grid grid-cols-12 gap-x-6 px-2 py-2 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-md hover:bg-gray-50"
            >
              <div className="col-span-2 sm:col-span-3 flex min-w-0 gap-x-4">
                <ProjectIcon iconSrc={event.solutionIcon} className="" />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm/6 font-semibold text-gray-900">
                    <a href="#">
                      <span className="absolute inset-x-0 -top-px bottom-0" />
                      <span className="overflow-hidden text-ellipsis whitespace-nowrap">{event.name}</span>
                    </a>
                  </p>
                  <p className="flex text-xs/5 text-gray-500 ">{event.solutionName}</p>
                </div>
              </div>
              <div className="hidden col-span-2 sm:flex items-center justify-center gap-x-4 text-sm">
                {event.buildDate.toDateString()}
              </div>
              <div className="hidden col-span-2 sm:flex items-center justify-center gap-x-4 text-sm">
                {event.eventDate.toDateString()}
              </div>
              <div className="hidden col-span-2 sm:flex items-center justify-center gap-x-4 text-sm">
                {event.deleteDate.toDateString()}
              </div>
              <div className="hidden col-span-2 sm:flex items-center justify-center gap-x-4 text-sm">
                <div className="overflow-hidden w-48 h-2 rounded-full bg-dataops-supporting-black/10">
                  <div
                    style={{ width: `${event.accountsAllocated}%` }}
                    className="h-2 rounded-full bg-dataops-primary-dark-blue transition-all duration-300"
                  />
                </div>
              </div>
              <div className="col-span-1 col-start-12 flex items-center justify-end gap-x-4">
                {/* Controls */}

                <div className="hidden md:flex shrink-0 items-center gap-x-4 ">
                  <ChevronRightIcon aria-hidden="true" className="size-5 flex-none text-gray-400" />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </PageCenteredLayout>
  );
}
