import React, { useEffect, useState } from 'react';
import { type IDeployment } from '../../reducers/deploymentsSlice';
import SolutionDeploymentsList from './SolutionDeploymentsList';
import { useDeployments } from '../../hooks/useDeployments';

export default function DeploymentsBySolutionList() {
  const { deployments } = useDeployments();
  const [deploymentsBySolution, setDeploymentsBySolution] = useState<{ [key: string]: IDeployment[] }>({});

  useEffect(() => {
    // Create groups of deployments by solution.
    const _deploymentsBySolution = deployments.reduce((acc: { [key: string]: IDeployment[] }, deployment) => {
      const solutionId =
        deployment.ciVariables.find((variable: any) => variable.key === 'DATAOPS_CATALOG_SOLUTION_TEMPLATE_ID')
          ?.value ?? 'unknown';

      if (solutionId === 'unknown') {
        return acc;
      }

      if (!acc[solutionId]) {
        acc[solutionId] = [];
      }
      acc[solutionId].push(deployment);
      return acc;
    }, {});
    setDeploymentsBySolution(_deploymentsBySolution);
  }, [deployments]);

  return (
    <ul role="list" className="w-full space-y-4">
      {Object.entries(deploymentsBySolution).map(([solutionId, deployments]) => (
        <SolutionDeploymentsList key={solutionId} solutionId={solutionId} deployments={deployments} />
      ))}
    </ul>
  );
}
