import React, { useEffect } from 'react';
import './App.css';
import SidebarLayout from './components/layout/SidebarLayout';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import OAuthCallback from './components/pages/OAuthCallback';
import { fetchUser } from './reducers/accountSlice';
import { useAppDispatch } from './hooks';
import Search from './components/pages/Search';
import LastVisitedUrlTracker from './components/nav/LastVisitedUrlTracker';
import DeploymentsPage from './components/pages/DeploymentsPage';
import NewSolutionPage from './components/pages/NewSolutionPage';
import NewFromTemplate from './components/pages/NewFromTemplate';
import NewFromNotebook from './components/pages/NewFromNotebookPage';
import NewFromGitPath from './components/pages/NewFromGitPage';
import Imported from './components/pages/Imported';
import Import from './components/pages/Import';
import SolutionPage from './components/pages/SolutionPage';
import SupportPage from './components/pages/SupportPage';
import DeploymentPage from './components/pages/DeploymentPage';
import ManageSolutionDraftsPage from './components/pages/ManageSolutionDraftsPage';
import { useToken } from './hooks/useToken';
import NoMatch from './components/pages/NoMatch';
import HeroLayout from './components/layout/HeroLayout';
import EventManager from './components/pages/EventManager';

declare global {
  interface Window {
    Appcues: any;
  }
}

export default function App() {
  const token = useToken();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (token !== '') {
      dispatch(fetchUser(token)).catch((err) => {
        console.log('Fetch username error:', err);
      });
    }
  }, [token]);

  return (
    <div>
      <Router>
        <LastVisitedUrlTracker />
        <Routes>
          <Route element={<HeroLayout />}>
            <Route path="/s" element={<Search quickstartResults />} />
          </Route>
          <Route element={<SidebarLayout />}>
            <Route path="*" element={<NoMatch />} />
            <Route path="/" element={<Navigate to="/solutions" replace />} />
            <Route path="/solutions" element={<Search />} />
            <Route path="/solutions/:id" element={<SolutionPage />} />
            <Route path="/deployments" element={<DeploymentsPage />} />
            <Route path="/deployments/:id" element={<DeploymentPage />} />
            <Route path="/new" element={<NewSolutionPage />} />
            <Route path="/new/from-framework" element={<NewFromTemplate />} />
            <Route path="/new/from-notebook" element={<NewFromNotebook />} />
            <Route path="/new/from-git" element={<NewFromGitPath />} />
            <Route path="/new/from-git/:id" element={<Imported />} />
            <Route path="/new/from-git/import/:id" element={<Import />} />
            <Route path="/manage/solution-drafts" element={<ManageSolutionDraftsPage />} />
            <Route path="/events" element={<EventManager />} />
            <Route path="/support" element={<SupportPage />} />
          </Route>
          <Route path="/dataops-oauth-callback" element={<OAuthCallback />} />
        </Routes>
      </Router>
    </div>
  );
}
