import { getConfig } from '../config/config-helper';
import { store } from '../store';
import { selectUser } from '../reducers/accountSlice';
import { parseGuid } from './parsers';

function getUserFromStore() {
  // Dispatch the action with the desired payload
  return selectUser(store.getState());
}

/**
 * Deprecated interface. Use sendEvent's IMetricEvent instead.
 */
export interface IAnalyticsData {
  event_name: string;
  event_source: string;
  properties?: {
    [key: string]: any;
  };
}

/**
 * Deprecated function. Use sendEvent function instead.
 */
export function sendMetrics(analyticsData: IAnalyticsData) {
  console.error('Deprecated function. Use sendEvent instead for this event', analyticsData);
}

export type EventName =
  | 'navigation-solutions-clicked'
  | 'navigation-deployments-clicked'
  | 'navigation-new-clicked'
  | 'navigation-drafts-clicked'
  | 'navigation-events-clicked'
  | 'navigation-help-clicked'
  | 'navigation-tab-clicked'
  | 'new-snowflake-notebook-start'
  | 'new-snowflake-notebook-end' // Missing for now
  | 'new-framework-template-start'
  | 'new-framework-template-end' // Missing for now
  | 'new-git-import-start'
  | 'new-git-import-from-url'
  | 'new-git-import-from-zip'
  | 'new-git-import-end'
  | 'help-system-docs-start'
  | 'help-dataopslive-docs-start'
  | 'help-powered-by-dataops-docs-start'
  | 'solution-search-end'
  | 'solution-unstared'
  | 'solution-stared'
  | 'solution-url-copied'
  | 'deployment-url-copied'
  | 'deployment-checkout-start'
  | 'deployment-checkout-end'
  | 'deployment-update-start'
  | 'deployment-validate-start'
  | 'deployment-share-customer-url-copied'
  | 'deployment-share-customer-url-generated'
  | 'deployment-salesforce-id-updated'
  | 'deployment-homepage-opened';

export interface IMetricEvent {
  name: EventName;
  properties?: { [key: string]: any };
}

/**
 * Send an event to the analytics endpoint
 *
 * @param name The name of the event
 * @param properties Additional properties to send with the event
 * @returns void
 *
 * @example
 * sendEvent({ name: 'navigation-solutions-clicked' });
 * sendEvent({ name: 'navigation-solutions-clicked', properties: { solutionId: '123' } });
 *
 * @see https://dataopslive.atlassian.net/wiki/x/AQD4Kw
 */
export function sendEvent({ name, properties }: IMetricEvent): void {
  const { dataopsShareEnvironment } = getConfig();
  const user = getUserFromStore();
  const enrichedAnalyticsData = {
    event_name: `dataops-sc-ui-${name}`,
    event_source: 'frostbyte-deployment-portal',
    properties: {
      ...properties,
      environment: dataopsShareEnvironment,
      userId: parseGuid(user?.id ?? ''),
      userEmail: user?.emails.nodes[0].email,
      currentUrl: window.location.href,
    },
  };
  const analyticsUrl = 'https://ms5iwqbfij.execute-api.eu-west-2.amazonaws.com/metric';
  fetch(analyticsUrl, {
    method: 'POST',
    mode: 'no-cors',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(enrichedAnalyticsData),
  }).catch(() => {
    console.error('Failed to send event', enrichedAnalyticsData);
  });
}
