import { type ResultViewProps } from '@elastic/react-search-ui-views';
import { type SearchResult } from '@elastic/search-ui';
import React, { useEffect } from 'react';
import { useSolution } from '../../hooks/useSolution';
import ProjectIcon from '../common/ProjectIcon';
import { useAppDispatch } from '../../hooks';
import { fetchSolution } from '../../reducers/solutionsSlice';
import { useToken } from '../../hooks/useToken';
import Tag from '../common/Tag';
import TierTag from './TierTag';
import NewDeploymentButton from '../controls/NewDeploymentButton';
import { UserIcon } from '@heroicons/react/16/solid';
import { OpenTier4 } from '../controls/OpenTier4';

interface Props extends ResultViewProps {
  result: SearchResult;
}

export default function SolutionResult({ result }: Props) {
  const dispatch = useAppDispatch();
  const token = useToken();

  const solutionId: string = result?.dataops_project_id?.raw ?? '';
  const listingName = result?.name?.raw;
  const listingAvatarUrl = result?.icon?.raw;
  const { solution } = useSolution(solutionId);
  const solutionPageUrl = `/solutions/${solutionId}`;

  const avatarUrl = solution?.avatarUrl ?? listingAvatarUrl;

  useEffect(() => {
    if (solution.requestExtraStatus === 'empty') {
      dispatch(fetchSolution({ token, fullPath: solution.fullPath, iid: solutionId })).catch((err) => err);
    }
  }, [solution.requestExtraStatus]);

  return (
    <li
      key={solutionId}
      className="relative h-full col-span-1 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-md hover:bg-gray-50"
      data-testid="search-result-item"
    >
      <div className="flex h-full w-full items-start justify-between space-x-6 p-6">
        <ProjectIcon iconSrc={avatarUrl} />
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <p className="text-md font-normal text-gray-900">
              <a href={solutionPageUrl}>
                <span className="absolute inset-x-0 -top-px bottom-0" />
                <span className="overflow-hidden text-ellipsis">{listingName}</span>
              </a>
            </p>
          </div>
          <div className="flex mt-2 space-x-1">
            <Tag color={'lightGray'}>
              <UserIcon className="w-3 h-3 mr-1" />
              <span>{solution?.listing?.creator}</span>
            </Tag>
            <TierTag tier={solution?.listing?.tier} />
          </div>
          <div className="flex mt-4 items-center space-x-1">
            {solution?.listing?.description && (
              <p className="text-xs text-gray-500 line-clamp-2 text-wrap">{solution?.listing?.description}</p>
            )}
          </div>
        </div>
        {solution?.listing?.tier < 4 ? (
          <NewDeploymentButton solution={solution} size="medium" buttonText="Try it" />
        ) : (
          <OpenTier4 solution={solution} size="medium" />
        )}
      </div>
    </li>
  );
}
