/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import { CheckCircleIcon, CheckIcon, SquaresPlusIcon } from '@heroicons/react/24/outline';
import Button from './Button';
import { sendMetrics } from '../../utilities/analytics';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import SetupModal from '../modals/setup/SetupModal';
import { type ISolution } from '../../reducers/solutionsSlice';
import {
  Checkbox,
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Field,
  Input,
  Label,
  Radio,
  RadioGroup,
} from '@headlessui/react';
import { classNames } from '../../utilities/styleUtils';
import SolutionQuickstartIcon from '../../assets/images/icons/Solution_quickstart_icon.svg';

interface Props {
  solution: ISolution;
  size?: 'medium' | 'large';
  buttonText?: string;
}

export default function TryQuickstartButton({ solution, size = 'medium', buttonText = 'Try it' }: Props) {
  const [projectSetupOpen, setProjectSetupOpen] = useState<boolean>(false);
  const { username } = useCurrentUser();
  const cancelButtonRef = useRef(null);

  const name = solution?.listing?.name ?? '...';
  const verticalThemes = solution?.listing?.vertical_themes;
  const preInstanceSetupMessage = solution?.listing?.pre_instance_setup_message;
  const preInstanceSetupChecklist = solution?.listing?.pre_instance_setup_checklist;
  const requiredDataShares = solution?.listing?.required_data_shares;
  const customerNameSuffix = solution?.listing?.customer_name_suffix;

  const clickSetupNewSolution = () => {
    console.log('clickSetupNewSolution');
    setProjectSetupOpen(true);
    // const analyticsData = {
    //   event_name: 'dataops-solution-homepage-new-quickstart-button-click',
    //   event_source: 'frostbyte-deployment-portal',
    //   properties: { username, project_name: name, dataops_project_id: solution.id },
    // };
    // sendMetrics(analyticsData);
  };

  return (
    <div className="flex flex-col">
      <SetupTryQuickstart open={projectSetupOpen} setOpen={setProjectSetupOpen} solution={solution} />
      <Button
        id="setup-new-solution-button"
        onClick={() => {
          clickSetupNewSolution();
        }}
        intent="primary"
        size={size}
        className="z-10"
        data-testid="setup-new-solution-button"
      >
        <SquaresPlusIcon className="w-4 h-4 mr-1" />
        {buttonText}
      </Button>
    </div>
  );
}

export function SetupTryQuickstart({
  open,
  setOpen,
  solution,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  solution: ISolution;
}) {
  const [step, setStep] = useState(0);
  const [status, setStatus] = useState('Getting your account and demo ready. It will open in a new tab shortly.');

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0  bg-white/90  transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed px-4 pb-4 pt-5 inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative flex justify-center -translate-y-36 transform overflow-hidden rounded-md bg-white text-left shadow-sm transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="flex flex-col w-full items-center justify-center">
              <section className={classNames('sm:min-w-[800px] space-y-8', step === 0 ? '' : 'hidden')}>
                <h2 className="text-4xl font-snow uppercase text-dataops-primary-light-blue">
                  <div>Let&apos;s try</div>
                  <div className="text-dataops-supporting-black/70">
                    {solution.listing?.name ? solution.listing?.name : 'This demo'}
                  </div>
                </h2>
                <Field>
                  <Label className="text-sm/6">Email address</Label>
                  <Description className="text-sm/6 text-dataops-supporting-black/50">
                    Use an email address to sign-up and into Snowflake.
                  </Description>
                  <Input
                    className={classNames(
                      // 'mt-3 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 ',
                      // 'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-sky-500/25',
                      'h-[33px] w-1/2 border border-gray-200 bg-transparent pl-2 pr-2 text-gray-900 placeholder:text-gray-500 ring-0 focus:ring-0 sm:text-sm rounded-md outline-none outline-offset-0 focus:outline focus:outline-dataops-primary-light-blue/20 focus:outline-4 transition-all ease-in-out',
                    )}
                  />
                </Field>
                <div className="flex mt-4 gap-x-2">
                  <Button
                    intent={'primary'}
                    size={'mediumPlus'}
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    <CheckIcon className="w-4 h-4 mr-1" />
                    Next
                  </Button>
                  <Button
                    intent={'subtle'}
                    size={'mediumPlus'}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Back
                  </Button>
                </div>
              </section>
              <section className={classNames('sm:min-w-[800px] space-y-8', step === 1 ? '' : 'hidden')}>
                <h2 className="text-4xl font-snow uppercase text-dataops-primary-light-blue">
                  <div>Let&apos;s try</div>
                  <div className="text-dataops-supporting-black/70">
                    {solution.listing?.name ? solution.listing?.name : 'This demo'}
                  </div>
                </h2>
                <Field>
                  <Example />
                </Field>
                <div className="flex mt-4 gap-x-2">
                  <Button
                    intent={'primary'}
                    size={'mediumPlus'}
                    onClick={() => {
                      setStep(2);
                      setTimeout(() => {
                        setOpen(false);
                      }, 6000);
                      setTimeout(() => {
                        console.log('Launching Quickstart..');
                        setStatus('Launching Quickstart..');
                        // open in a new tab https://app.snowflake.com/moiwelb/private_previews/#/notebooks/DATAOPS_DEV.PUBLIC.%22Consumer%20Demo%22
                        window.open(
                          'https://app.snowflake.com/moiwelb/private_previews/#/notebooks/DATAOPS_DEV.PUBLIC.%22Consumer%20Demo%22',
                          '_blank',
                        );
                      }, 5000);
                    }}
                  >
                    <CheckIcon className="w-4 h-4 mr-1" />
                    Go!
                  </Button>
                  <Button
                    intent={'subtle'}
                    size={'mediumPlus'}
                    onClick={() => {
                      setStep(0);
                    }}
                  >
                    Back
                  </Button>
                </div>
              </section>
              <section className={classNames('sm:min-w-[800px] space-y-8', step === 2 ? '' : 'hidden')}>
                <h2 className="text-4xl font-snow uppercase text-dataops-primary-light-blue">
                  <div>Launching Quickstart..</div>
                  <div className="text-dataops-supporting-black/70">
                    {solution.listing?.name ? solution.listing?.name : 'This demo'}
                  </div>
                </h2>
                <img src={SolutionQuickstartIcon} className="size-32 animate-bounce" />
                <div className="italic">{status}</div>
              </section>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

const plans = [
  { iid: 0, name: 'Use our Quickstart Snowflake account', ram: 'Fastest', cpus: 'Temporary', disk: 'Secure' },
  { iid: 1, name: 'Create new account', ram: 'Slower', cpus: 'Yours forever', disk: 'Secure' },
];

const newAccountFields = [
  { label: 'First Name', type: 'text' },
  { label: 'Last Name', type: 'text' },
  { label: 'Company Email', type: 'text' },
  { label: 'Company Name', type: 'text' },
  { label: 'Role', type: 'text' },
  { label: 'Country', type: 'text' },
  {
    label:
      'No, I do not want Snowflake to send me e-mails about products, services, and events that it thinks may interest me.',
    type: 'checkbox',
  },
];

// First Name*
// Last Name*
// Company Email*
// Company Name*

// Role*

// United Kingdom

// No, I do not want Snowflake to send me e-mails about products, services, and events that it thinks may interest me.
// By clicking the button below you understand that Snowflake will process your personal information in accordance with its Privacy Notice

export function Example() {
  const [selected, setSelected] = useState(plans[0]);
  const [enabled, setEnabled] = useState(false);
  return (
    <div className="w-full px-4">
      <div className=" w-full max-w-md">
        <RadioGroup value={selected} onChange={setSelected} aria-label="Server size" className="space-y-2">
          {plans.map((plan) => (
            <Radio
              key={plan.name}
              value={plan}
              className="group relative flex cursor-pointer rounded-md bg-white/5 py-4 px-5 text-dataops-primary-dark-blue shadow-sm transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-dataops-primary-dark-blue focus:outline-dataops-primary-light-blue/20 data-[checked]:bg-dataops-primary-light-blue/10 hover:bg-dataops-primary-dark-blue/5 "
            >
              <div className="flex w-full items-center justify-between">
                <div className="text-sm/6">
                  <p className="font-normal text-lg text-dataops-primary-dark-blue">{plan.name}</p>
                  <div className="flex gap-2 text-dataops-primary-dark-blue/50">
                    <div>{plan.ram}</div>
                    <div aria-hidden="true">&middot;</div>
                    <div>{plan.cpus}</div>
                    <div aria-hidden="true">&middot;</div>
                    <div>{plan.disk}</div>
                  </div>
                </div>
                <CheckCircleIcon className="size-6 text-dataops-primary-light-blue opacity-0 transition group-data-[checked]:opacity-100" />
              </div>
            </Radio>
          ))}
        </RadioGroup>
        <div className={classNames('flex flex-col mt-4 gap-x-2 space-y-4', selected.iid === 1 ? '' : 'hidden')}>
          {newAccountFields.map((field) => {
            if (field.type === 'text') {
              return (
                <Field key={field.label}>
                  <Label className="text-sm/6">{field.label}</Label>
                  <Input
                    className={classNames(
                      // 'mt-3 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 ',
                      // 'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-sky-500/25',
                      'h-[33px] w-full border border-gray-200 bg-transparent pl-2 pr-2 text-gray-900 placeholder:text-gray-500 ring-0 focus:ring-0 sm:text-sm rounded-md outline-none outline-offset-0 focus:outline focus:outline-dataops-primary-light-blue/20 focus:outline-4 transition-all ease-in-out',
                    )}
                  />
                </Field>
              );
            } else {
              return (
                <Field key={field.label} className="flex items-center space-x-8">
                  <Checkbox
                    checked={enabled}
                    onChange={setEnabled}
                    className="group size-6 rounded-md bg-dataops-primary-light-blue/10 p-1 ring-1 ring-dataops-primary-light-blue/15 ring-inset data-[checked]:bg-dataops-primary-light-blue"
                  >
                    <CheckIcon className="size-4 hidden group-data-[checked]:block" />
                  </Checkbox>
                  <Label className="text-xs/4 text-dataops-primary-dark-blue/70 w-3/4">{field.label}</Label>
                </Field>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
